import React from "react";

const Imprint = () => {
  return (
    <section id="three">
      <div className="container">
        <h3>Impressum</h3>
        <p>
          Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV
          <br /> <b>Bernd Funk</b>
        </p>
        <p>
          Systemische Supervision und Coaching
          <br /> Strandläuferweg 5 <br />
          25899 Niebüll
          <br />
          Tel: 0171 8356307 <br />
          E-Mail: info@bernd-funk.net
        </p>
      </div>
    </section>
  );
};
export default Imprint;
