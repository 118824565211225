import * as React from "react";
import Imprint from "../components/imprint";
import Footer from "../components/footer";

const ImprintPage = () => {
  return (
    <main>
      <div id="wrapper">
        <div id="main">
          <Imprint />
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default ImprintPage;
