import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <section id="footer">
      <div className="container">
        <ul className="copyright">
          <li>
            <Link to="/"> bernd-funk.net</Link>
          </li>
          <li>
            <Link to="/impressum">Impressum</Link>
          </li>
          <li>
            <Link to="/datenschutz">Datenschutz</Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Footer;
